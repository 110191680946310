<template>
	<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >UUID在线生成</h2>
		<el-row style="margin-top: 4%;">
		<el-col :sm="11" style="margin: 0 auto; text-align: center;">
			<el-form :model="uuidData">
				<el-form-item label="生成数量" >
						<el-col :sm = "24">
							<el-input  v-model.number="uuidData.size" placeholder="请输入生成数量"></el-input>
						</el-col>
				</el-form-item>
				
				<el-form-item>
					<el-col :sm = "20">
						<el-radio-group v-model="uuidData.length">
							<el-radio v-model="radio" label="32">32位</el-radio>
							<el-radio v-model="radio" label="16">16位</el-radio>
						</el-radio-group>
						<el-checkbox v-model="uuidData.removeSeparator" style="padding-left: 30px;">生成的 UUID 中不使用中横线 - 连接</el-checkbox>
					</el-col>
				</el-form-item>
				
				<el-form-item label="">
					<el-col :sm = "4"></el-col>
					<el-input readonly  type="textarea" :rows="uuidData.rows" resize='none'  v-model="uuidData.text"></el-input>
				</el-form-item>
				
				<div style="margin: 0 auto; text-align: center;">
					<el-button id="1001" type="primary" @click="generate()">生成</el-button>
					<el-button  @click="copy(uuidData.text)" type="primary" plain>复制</el-button>
				</div>
			</el-form>
	</el-col>
	</el-row>
</template>

<script setup>
import {ref} from 'vue'
import {v4 as uuid} from 'uuid'
import {numberule as checknumber} from '../../common/validate'
import {moduleEvent as moduleaction} from '../../common/actionrecord'
import {clipboard as copy} from '../../common/actionrecord'	

    let uuidData = ref({
		moduleid: 1007,
        size: 10,
        rows: 10,
		length: "32",
		removeSeparator: true,
        text: ""
    })
	
	moduleaction(uuidData.value.moduleid,1);
    let generate = () => {
		
		//校验代码
		var validresult = checknumber(uuidData.value.size,1000);
		if(!validresult.success){
			uuidData.value.text = validresult.info;
			return;
		}
		
        let uuids = new Array(parseInt(uuidData.value.size))
        .fill()
        .map(() => {
			let uuidStr = uuid();
			if (uuidData.value.length == "16") {
				uuidStr = uuidStr.substring(0, 18);
			}
			if(uuidData.value.removeSeparator){
				uuidStr = uuidStr.replace(/-/g, "")
			}
			return uuidStr;
		});

        //uuidData.value.rows = uuidData.value.size;
        uuidData.value.text = uuids.join("\n");
		//模块事件1.预览 2.使用
		moduleaction(uuidData.value.moduleid,2);
    }
//进入页面，触发数据生成逻辑
generate();
</script>

<style>
</style>